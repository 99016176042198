import * as bootstrap from 'bootstrap';
import slick from 'slick-carousel';
import AOS from 'aos';
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;


/*!
    * IE10 viewport hack for Surface/desktop Windows 8 bug
    * Copyright 2014-2017 The Bootstrap Authors
    * Copyright 2014-2017 Twitter, Inc.
    * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
    */

// See the Getting Started docs for more information:
// https://getbootstrap.com/getting-started/#support-ie10-width
(function () {
  'use strict'
  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
      document.createTextNode(
        '@-ms-viewport{width:auto!important}',
        '*::-ms-backdrop,[data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{opacity:1!important;}'
      )
    )
    document.head.appendChild(msViewportStyle)
  }
}());

//move Background
$(document).ready(function(){
  
  //sliders
  $(".slider").slick({
    dots: true,
    infinite: true,
    arrows: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: "<button type='button' class='slick-prev'><i class='fas fa-angle-left' aria-hidden='true'></i></button>",
    nextArrow: "<button type='button' class='slick-next'><i class='fas fa-angle-right' aria-hidden='true'></i></button>",
    adaptiveHeight: false,
    fade: true
  });

  //animate items
  AOS.init();

  //do window resize
  $(window).resize();

  $(document).on('click', 'a[href^="#"]:not("#nav-buttons a")', function (e) {
    var id = $(this).attr('href');
    var $id = $(id);
    if ($id.length === 0) {
      return;
    }
    e.preventDefault();
    var pos = $id.offset().top;
    $('body, html').animate({ scrollTop: pos });
  });

  $('.navbar-nav>li>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });
  
});
